<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import MenuComponent from '@/components/MenuComponent.vue';
import SuperMenuComponent from '@/components/SuperMenuComponent.vue';
import SwitchInputComponent from '@/components/form/SwitchInputComponent.vue'
import SvgComponent from '@/components/SvgComponent.vue';
import SearchResultComponent from '@/components/form/SearchResultComponent.vue';
import { useScreenSizeBehaviours } from '@/composables/useScreenSizeBehaviours';
import { useStore } from 'vuex';
import router from '@/router'

const store = useStore()

const { isMobileSize } = useScreenSizeBehaviours()
const isToggled = computed(() => store.getters['event/getIsToggled'])
const beforeChangeViewPortSize = ref(false)
const dashType = ref(1)

const isSuper = computed(() => store.getters['user/isSuper'])
const isAffiliate = computed(() => store.getters['user/isAffiliate'])
const tabs = computed(() => store.getters['superAdm/getTabs'])
const activeTab = computed(() => store.getters['superAdm/getActiveTab'])
const activeUser = computed(() => store.getters['superAdm/getActiveUser'])

const getAvatar = avatar => avatar 
    ? `${process.env.VUE_APP_API_URL}/storage/avatar/${avatar}`
    : require('@/assets/images/user_fallback_avatar.png')

function toggleSidebar() {
    store.commit('event/TOGGLE_SIDEBAR')
}

function onClickLogo() {
    router.push({ name: "home" })
}

const selectTab = index => {
    store.dispatch('superAdm/selectTab', index)
}

const closeTab = index => {
    store.dispatch('superAdm/removeTab', index)
}

watch(isMobileSize, (newVal) => {
    if (newVal) {
        beforeChangeViewPortSize.value = isToggled.value
        if (!isToggled.value) {
            toggleSidebar()
        }
    } else {
        if (isToggled.value != beforeChangeViewPortSize.value) {
            toggleSidebar()
        }
    }
})

watch(activeUser, () => {
    const dashRoutes = {
        SUPPLIER: '/dashboard',
        AFFILIATE: '/affiliates/dashboard',
        SUPER: '/super-admin/dashboard',
    }

    router.push({ 
        path: dashRoutes[activeUser.value.user_type] ?? '/super-admin/dashboard' 
    })
})

watch(() => dashType.value, (newVal) => {
    if (newVal === 0) {
        router.push({ path: '/user-panel/bookings' })
    }
})

onMounted(async () => {
    if (isMobileSize.value) {
        toggleSidebar()
    }

    if (isSuper.value && store.getters["superAdm/getTabs"].length === 0) {
        store.dispatch('superAdm/initTabs', {
            id: -1,
            name: 'Super Admin',
            user_type: 'SUPER',
        })
    }
})
</script>

<template>
    <aside :class="{ toggled: isToggled }">
        <ButtonComponent class="toggle-sidebar" color="transparent" @click="toggleSidebar">
            <SvgComponent name="IconHamburgerDesktop" />
        </ButtonComponent>

        <SvgComponent @click="onClickLogo()" class="logo-icon" name="IconLogo" v-if="isToggled"/>
        <SvgComponent @click="onClickLogo()" class="logo" name="SeeLogo" v-else />

        <div class="mobile-menu-header">
            <span>Menu</span>
            <SvgComponent class="close-button" name="IconClose" @click="toggleSidebar" />
        </div>

        <div v-if="isSuper" class="search-input-box">
            <SearchResultComponent
                v-model="searchString"
                :autocompleteSuggestion="autocompleteSuggestion"
                @performSearch="performSearch"
            />

            <div v-for="(tab, index) in tabs" :key="index">
                <div class="tab-result" :class="{'tab-active': index == activeTab }" @click="selectTab(index)">
                    <div v-if="index === 0" class="bold tab-result-item ">{{ tab.name }}</div>
                    <div v-else class="tab-result-item">
                        <img :src="getAvatar(tab?.avatar[0]?.file_name || '')" class="tab-result-image" width="40" />
                        <div>
                            <p class="bold">{{ tab.user_type === 'SUPPLIER' ? tab.company?.trade_name : tab.name }}</p>
                            <div class="tab-result-info">
                                <p>ID: {{ tab.id }}</p>&nbsp;&nbsp;
                                <p>{{ tab.user_type }}</p>
                            </div>
                        </div>
                        <SvgComponent v-if="index > 0" name="IconXClose" class="icon-close" @click="closeTab(index)" />
                    </div>
                </div>
                <div class="divider"></div>
            </div>
        </div>

        <div v-if="isAffiliate" class="switch-dash">
            <SwitchInputComponent
                :leftOption="{ label: 'DASHBOARD', val: 1 }"
                :rightOption="{ label: 'MY BOOKINGS', val: 0 }"
                v-model="dashType"
            />
        </div>

        <div class="scrollable">
            <SuperMenuComponent :key="activeTab" v-if="isSuper" :class="{toggled: isToggled}" />
            <MenuComponent v-if="!isSuper" :class="{toggled: isToggled}" />

            <div v-if="! isSuper" class="support-sticker-spacer"></div>

            <div v-if="! isSuper" class="support-sticker">
                <a href="tel:00351939182666" class="support-sticker-icon">
                    <img src="@/assets/svg/ringingPhone.svg" alt="Call support">
                </a>
                <div class="support-sticker-content">
                    <span>SUPPORT</span>
                    <a href="tel:00351915377957">(+351) 915 377 957</a>
                    <a href="mailto:partner.support@seecountries.com">partner.support@seecountries.com</a>
                </div>
            </div>
        </div>
    </aside>
</template>

<style lang="scss" scoped>
.search-input-box {
    margin: 10px 0px;
}

.switch-dash {
    margin-left: 20px;
}

.support-sticker{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 9px;
    
    padding: 23px 16px;
    border-radius: 12px;
    background: var(--tertiary-color);

    @media (min-width:768px) {
        width: 100%;
        margin-bottom:20px;
    }

    @media (max-width:767px) {
        width: calc(100% - 26px);
        margin-left: 14px;
    }

    .support-sticker-content{
        text-align:right;

        span, a{
            display:block;
            color: var(--light-color);
            font-family: Montserrat;
        }

        span{

            font-family: Montserrat;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        a{
            
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            @media (min-width:768px) {
                font-size: 11px;
            }
            @media (max-width:767px) {
                font-size: 13px;
            }
        }
    }

    .support-sticker-icon{
        
        @media (min-width:768px) {
            width:43px;
            height:43px;
        }

        @media (max-width:767px) {
            width: 48.466px;
            height: 48.466px;
        }
        
        img{
            width:100%;
            height:auto;
        }
    }
}
aside {
    top: 0;
    height: calc(100vh + 200px);
    background-color: var(--light-color);
    // z-index: 9999;
    transition: ease-out .3s;
    height: 100%;

    @media (min-width:768px) {
        position: sticky;
        width: 365px;
        margin: -20px 35.75px -70px -20px;
        padding: 14px 33.5px;
    }

    @media (max-width:767px) {
        position:fixed;
        width:100%;
        left:0;
        z-index: 999999;
    }

    &.toggled {
        @media (min-width:768px) {
            padding-left: 21px;
            padding-right: 21px;
            width: 95px;
        }
        @media (max-width:767px) {
            display:none;
        }

        @media (min-width:768px) {

            .support-sticker{
                display: none;
            }
        }
    }

    .scrollable {
        overflow-y: auto;
        height: calc(100vh + 200px);
        margin: 0 -33.5px;
        padding: 0 33.5px;

        @media (max-width:767px) {
            height:calc(100vh - 103px);
        }

        display: flex;
        flex-direction: column;
    }

    .logo {
        margin-bottom: 33px;
        
        @media (max-width:767px) {
            display:none;
        }
    }

    .logo-icon {
        margin-bottom: 100px;
    }

    .toggle-sidebar {
        position: absolute;
        left: calc(100% + 35px);

        @media (max-width:767px) {
            display:none;
        }
    }
}
.mobile-menu-header {
    @media (min-width:768px) {
        display:none;
    }
    @media (max-width:767px) {
        display:flex;
    }
    justify-content: space-between;
    align-items:center;
    padding: 24px 17px 48px 37px;

    span{
        color: var(--primary-color-v2);
        font-family: Montserrat;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }
}
.support-sticker-spacer{
    flex-grow:1;
    @media (max-width:767px) {
        display: none;
    }
}

.tab-result {
    display: flex;
    align-items: center;
    font-family: Montserrat;
    font-size: 13px;
    color: #96A2C2;
    margin: 10px 0px;
    padding: 15px;
    border-radius: 12px;
    background-color: #F6F6F8;
}

.tab-active {
    color: #FFFFFF !important;
    background-color: #FC6030 !important;
}

.divider {
    border-bottom: 0.1px solid #D0D4DE;
}

.tab-result-item {
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;
}

.tab-result-item p {
    margin-block-start: 1px;
    margin-block-end: 1px;
}

.tab-result-image {
    border-radius: 6px;
    margin-right: 10px;
}

.tab-result-info {
    display: flex;
    justify-content: space-between;
}

.icon-close {
   margin-left: 10%;
}

.bold {
    font-weight: 500;
}
</style>